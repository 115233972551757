<script lang="ts" setup>
import type { LightStealthPath, LightStealthRoute, Permission } from '@/types'

import { lightstealthGeneralRoutes, lightstealthInternalToolsRoutes, lightstealthToolsRoutes } from '@/utils/lightstealthRouteConfig'
import { userSettings } from '@/stores/userSettings'

defineOptions({
  name: 'Navigator',
})

const { t } = useI18n()
const userSettingsStore = userSettings()

// Cache user permissions
const userPermissionsSet = computed(() => {
  return new Set(userSettingsStore.permissions)
})

function isPermitted(permissionsNeeded: Permission[], every = false): boolean {
  const userPermissions = userPermissionsSet.value
  if (permissionsNeeded.length === 0) {
    return true
  }
  if (every) {
    return permissionsNeeded.every((permission) => {
      return userPermissions.has(permission)
    })
  }
  return permissionsNeeded.some((permission) => {
    return userPermissions.has(permission)
  })
}

function toSnakeCase(str: string): string {
  return str.replace(/-/g, '_')
}

function mapRoute(item: LightStealthRoute, children?: LightStealthRoute[]) {
  const namePath = `sidebar.${toSnakeCase(item.name)}`
  return {
    name: children ? t(`${namePath}.${toSnakeCase(item.name)}`) : t(namePath),
    locationName: item.name,
    icon: item.icon,
    permitted: isPermitted(item.permissions),
    children: children?.map((child) => {
      return {
        name: t(`${namePath}.${toSnakeCase(child.name)}`),
        locationName: child.name,
        icon: child.icon,
        permitted: isPermitted(child.permissions, child.isEveryPermissionNeeded),
      }
    }),
  }
}

// Configuration for adding extra children dynamically
const extraChildrenConfig: Record<string, LightStealthRoute[]> = {
  statistics: [
    {
      name: 'redshift-statistics',
      path: '/statistics/redshift' as LightStealthPath,
      permissions: ['redshift_statistics:view', 'area_user:view'],
      isEveryPermissionNeeded: true,
      multiArea: false,
    },
  ],
}

function generateNavigation(
  routes: LightStealthRoute[],
  extraChildren: Record<string, LightStealthRoute[]> = {},
) {
  return routes.map((item) => {
    let children = item.children

    if (extraChildren[item.name]) {
      children = [
        ...(item.children || []),
        ...extraChildren[item.name],
      ]
    }

    return mapRoute(item, children)
  })
}

// Computed navigation constants
const lightstealthGeneralNavigation = computed(() => {
  return generateNavigation(lightstealthGeneralRoutes, extraChildrenConfig)
})

const lightstealthInternalToolsNavigation = computed(() => {
  return generateNavigation(lightstealthInternalToolsRoutes)
})

const lightstealthToolsNavigation = computed(() => {
  return generateNavigation(lightstealthToolsRoutes)
})

// Full navigation
const navigation = computed(() => {
  return [
    {
      items: [
        {
          name: t('sidebar.dashboard'),
          locationName: 'home',
          icon: 'i-custom:dashboard',
          permitted: true,
        },
        ...lightstealthGeneralNavigation.value,
      ],
    },
    {
      name: t('sidebar.internal_tools'),
      items: lightstealthInternalToolsNavigation.value,
    },
    {
      name: t('sidebar.tools'),
      items: [
        ...lightstealthToolsNavigation.value,
        {
          name: t('sidebar.account_verification'),
          locationName: 'account-verification',
          icon: 'i-custom:users-check',
          permitted: isPermitted(['view:account_verification']),
        },
      ],
    },
  ]
})
</script>

<template>
  <div class="inset-y-0 z-20 ml-auto w-78 flex flex-col md:fixed md:ml-unset">
    <div class="h-26 shrink-0 items-center justify-between px-3 pb-10 pl pl-5 pt-8 hidden md:flex">
      <router-link to="/">
        <img src="@/assets/logo.svg" alt="Peter Park">
      </router-link>
    </div>
    <AppSidebar :navigation="navigation" />
  </div>
</template>
