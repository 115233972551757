import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@auth0/auth0-vue'

import { authentication } from '@/stores/authentication'
import { userSettings } from '@/stores/userSettings'

import HomeView from '@/views/Home.vue'
import AccountVerification from '@/views/AccountVerification/index.vue'
import AccountVerificationSummary from '@/views/AccountVerification/Summary/index.vue'

import LightstealthPageView from '@/views/lightstealth.vue'
import WizardView from '@/views/AccountVerification/Wizard.vue'

import { checkPermissions, getPermissions } from '@/utils'
import { setSuperProperties } from '@/utils/logger'

import { lightstealthGeneralRoutes, lightstealthInternalToolsRoutes, lightstealthToolsRoutes } from '@/utils/lightstealthRouteConfig'
import { useAuth } from '@/composables/useAuth'

export const lightstealthRoutes = [
  ...lightstealthGeneralRoutes,
  ...lightstealthInternalToolsRoutes,
  ...lightstealthToolsRoutes,
].flatMap((route) => {
  const routes = [{
    path: route.path,
    name: route.name,
    component: LightstealthPageView,
    beforeEnter: authGuard,
    meta: {
      // user has to have minimum one of the permissions
      permissionsNeeded: route.permissions,
      isEveryPermissionNeeded: route.isEveryPermissionNeeded,
      multiArea: route.multiArea,
      lightstealth: true,
    },
  }]

  if (route.children && Array.isArray(route.children)) {
    const childRoutes = route.children.map(child => ({
      path: child.path,
      name: child.name,
      component: LightstealthPageView,
      beforeEnter: authGuard,
      meta: {
        permissionsNeeded: child.permissions,
        isEveryPermissionNeeded: child.isEveryPermissionNeeded,
        multiArea: child.multiArea,
        lightstealth: true,
      },
    }))
    routes.push(...childRoutes)
  }

  return routes
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      beforeEnter: authGuard,
      meta: {
        permissionsNeeded: [],
        multiArea: true,
      },
    },
    {
      path: '/account-verification',
      name: 'account-verification',
      component: AccountVerification,
      beforeEnter: authGuard,
      meta: {
        permissionsNeeded: ['view:account_verification'],
        multiArea: true,
      },
    },
    {
      path: '/account-verification/wizard',
      name: 'account-verification-wizard',
      component: WizardView,
      meta: {
        layout: 'WizardLayout',
        permissionsNeeded: ['view:account_verification'],
      },
    },
    {
      path: '/account-verification/:areaId',
      name: 'verification-summary',
      component: AccountVerificationSummary,
      meta: {
        permissionsNeeded: ['view:account_verification'],
      },
    },
    {
      path: '/statistics/redshift',
      name: 'redshift-statistics',
      component: () => import('@/views/Statistics/Redshift.vue'),
      meta: {
        permissionNeeded: ['redshift_statistics:view', 'area_user:view'],
        isEveryPermissionNeeded: true,
      },
    },
    {
      path: '/callback',
      name: 'auth0-callback',
      component: () => import('@/views/Loading.vue'),
      meta: {
        description: '',
      },
    },
    ...lightstealthRoutes,
  ],
})

router.beforeEach(async (to, _from, next) => {
  const authenticationStore = authentication()
  const { checkSession, isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth()

  await checkSession()
  setSuperProperties({ user: user.value?.name || 'unknown' })

  let canPerformOperation = false
  const { setPermissions } = userSettings()
  if (isAuthenticated.value) {
    const accessToken = await getAccessTokenSilently()
    authenticationStore.setAccessToken(accessToken)
    const permissions = getPermissions(accessToken)
    setPermissions(Array.from(permissions))
    if (to.meta.permissionsNeeded?.length && permissions.size > 0)
      canPerformOperation = checkPermissions(to.meta.permissionsNeeded, permissions, to.meta.isEveryPermissionNeeded)
  }
  if (!isAuthenticated.value && to.name !== 'auth0-callback') {
    loginWithRedirect()
  }
  // permission required to view the route is not present so we redirect the user to homepage instead
  if (to.meta.permissionsNeeded?.length && !canPerformOperation) {
    router.push({ name: 'home' })
    authenticationStore.setLastRouteAccessValid(false)
  }
  next()
})

export default router
